// @ts-nocheck
import React from 'react'
import { GridContainer, Layout, PageHeader } from '@ggs/components/layout'
import { graphql } from 'gatsby'
import { ProductGrid, Text } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import { zIndex } from '@ggs/styles'
import Box from '@mui/material/Box'
import { getWindow } from '@ggs/utils' /**
 * Layout sizes
 * @enum {string}
 */

/**
 * Layout sizes
 * @enum {string}
 */

/**
 *
 * @param {Object} queries
 * @param {Object} props
 * @return {JSX.Element}
 */
export default function FourOhFour({ queries: { site } = {}, ...props }) {
  const { t } = useI18n()
  const win = getWindow()
  const pageContext = props?.pageContext || {}
  const { featuredProducts, testimonialsTitle, featuredTestimonials, highlightCtas }
    = pageContext?.data || {}
  // Set page title
  pageContext.data.title = t('global:label.pageNotFound')

  return (
    <Layout
      className="page"
      site={site}
      pageContext={pageContext}
      sx={{
        p: 0,
        // '.customer-selector *, .store-selector *': {
        //   pointerEvents: 'none',
        //   cursor: 'default',
        // },
      }}
    >
      <PageHeader title={pageContext.data.title} />
      <Box
        sx={
          {
            // position: 'relative',
            // zIndex: zIndex.zContent,
            // '.proudct-grid': {
            //   zIndex: zIndex.zOverlay,
            // }
          }
        }
      >
        <GridContainer
          sx={{
            mt: 4,
            // mb: '40vh',
            textAlign: 'center',
            zIndex: zIndex.zHeader,
          }}
        >
          <Text textContent={t('global:notice.pageNotFound')} />
        </GridContainer>
        {featuredProducts && (
          <ProductGrid
            {...featuredProducts}
            theme={ProductGrid.variant.default}
            actionLabel={t('global:button.view')}
            type={'404'}
            id={'pageNotFound'}
          />
        )}
        {/*{featuredTestimonials && (*/}
        {/*  <Carousel*/}
        {/*    title={testimonialsTitle}*/}
        {/*    carouselItems={featuredTestimonials}*/}
        {/*    variant={Carousel.variant.largeQuotes}*/}
        {/*  />*/}
        {/*)}*/}
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query fourOhFourPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
